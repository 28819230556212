<template>
    <div class="project-create">
        <div class="manage-wrapper">
            <div class="project-create-container">
                <div class="content">
                    <div class="export-box flex-align">
                        <el-button-group>
                            <el-button size="mini" type="primary" :disabled="disabled" @click="edit">完成</el-button>
                            <el-button size="mini" type="primary" icon="el-icon-download"
                                @click="exportReport">导出</el-button>
                        </el-button-group>
                    </div>
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="默认报告" name="first">
                        </el-tab-pane>
                        <el-empty description="暂无报告" v-if="isEmpty"></el-empty>
                        <div class="report-box  flex-column-center" v-loading="loading" v-else>
                            <span class="report-title">{{ title }}</span>
                            <div class="quest-box">
                                <div class="quest-item" v-for="(item, index) in questList" :key="index">
                                    <div class="title">
                                        {{ item.q_title }}
                                    </div>
                                    <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="item.ai_content"
                                        :readonly="readonly" v-if="item.ai_content">
                                    </el-input>
                                    <div class="my-table" v-if="item.q_type == 3">
                                        <table border="1">
                                            <thead>
                                                <tr>
                                                    <th>选项</th>
                                                    <th class="th-count">小计</th>
                                                    <th>比例</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(items, index) in item.items">
                                                    <td>{{ items.item_title }}</td>
                                                    <td class="count">{{ items.item_selected_count || 0 }}</td>
                                                    <td>
                                                        <el-progress
                                                            :percentage="items.item_selected_count ? Number((items.item_selected_count / item.q_count * 100).toFixed(2)) : 0"
                                                            :stroke-width="10"></el-progress>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>本题有效填写人次</td>
                                                    <td class="count">{{ item.q_count || 0 }}</td>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tabs>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { exportWordAndExcel } from '../../../utils/exportWord'

export default {
    components: {
    },
    data() {
        return {
            activeName: "first",
            questList: [],
            title: '',
            loading: false,
            ai_content: '',
            readonly: this.$route.query.isEdit == 'true' ? false : true,
            isEmpty: false,
            radio: '1',
            disabled: this.$route.query.isEdit == 'true' ? false : true,
            parentId:''
        }
    },
    methods: {
        exportReport() {
            console.log('导出报告');
            exportWordAndExcel(this.questList, this.title)
        },
        // 获取表格报告数据
        async getWjxReportInfo() {
            let data = {
                vid: this.$route.query.rootMsgId
            }
            let resData = await this.$Api.wjx.getWjxReportInfo(data);
            console.log('问卷星报告:', resData);
            this.title = resData.title;
            resData.questions.map(item => {
                if (item.q_type == 3) {
                    let count = 0;
                    item.items.map(child => {
                        count += child.item_selected_count
                    })
                    item.q_count = count
                }
            })
            this.questList = resData.questions;
        },
        // 触发Ai报告
        async getKimiResult() {
            let data = {
                reportId: this.$route.query.reportId,
                msgId: this.$route.query.rootMsgId
            }
            let resData = await this.$Api.kimi.getReportResult(data);
            console.log('触发Ai报告结果:', resData);
            this.getWjxReportInfo()
        },
        // 获取报告
        async reportAiLast() {
            let data = {
                reportId: this.$route.query.reportId
            }
            let resData = await this.$Api.kimi.reportAiLast(data);
            console.log('获取报告:', resData);
            if (resData.data) {
                this.parentId = resData.data.parentId
                this.isEmpty = false;
                let content = JSON.parse(resData.data.content);
                this.title = content.title;
                content.questions.map(item => {
                    if (item.q_type == 3) {
                        let count = 0;
                        item.items.map(child => {
                            count += child.item_selected_count
                        })
                        item.q_count = count
                    }
                })
                this.questList = content.questions;
            } else {
                console.log('暂无报告');
                this.isEmpty = true
            }

        },
        // 编辑报告
        async edit() {
            let data = {
                id: this.parentId,
                content: JSON.stringify(this.questList)
            }
            let resData = await this.$Api.kimi.editRreport(data);
            console.log('编辑报告:', resData);
            if (resData.code == 200 && resData.data === true) {
                this.$notify.success({
                    title: "编辑成功",
                });
            }
        },
    },
    mounted() {
        if (this.$route.query.isView == 'true' || this.$route.query.isEdit == 'true') {
            this.reportAiLast()
        } else {
            this.getKimiResult();
        }

    }
};
</script>

<style lang="less" scoped>
@import "../manage_project/less/manage-project.less";

.content {
    position: relative;

    /deep/ .el-tabs__content {
        box-sizing: border-box;
        padding: 10px;
    }

    .export-box {
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex;
        margin-bottom: 10px;
        z-index: 999;

        span {
            margin-left: auto;

            .export-icon {
                display: inline-block;
                background-image: url("~@/assets/images/admin/export.png");
                background-size: 100% 100%;
                width: 17px;
                height: 16px;
                margin-right: 8px;
                vertical-align: -2px;
            }
        }

    }

    .report-box {
        .report-title {
            font-size: 18px;
            font-weight: bold;
        }

        .quest-box {
            margin-top: 30px;

            .quest-item {
                margin-bottom: 20px;

                .title {
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                .el-textarea {
                    margin-bottom: 10px;
                }

                .my-table {
                    font-size: 14px;

                    table {
                        border-color: #E0E0E0;

                        th {
                            height: 40px;
                            padding-left: 10px;
                        }

                        .th-count {
                            text-align: center;
                            padding-left: 0px;
                        }

                        td {
                            width: 300px;
                            height: 40px;
                            padding-left: 10px;
                        }

                        tr:hover {
                            background-color: #FAFAFA;
                        }

                        .count {
                            padding-left: 0px;
                            width: 70px;
                            text-align: center;
                        }

                        thead,
                        tfoot {
                            background-color: #F5F5F5;
                            font-weight: bold;
                        }

                        /deep/ .el-progress-bar {
                            width: 80%;
                        }

                        /deep/ .el-progress__text {
                            font-size: 14px !important;
                            color: #333 !important;
                        }
                    }
                }
            }
        }

    }
}
</style>